<div class="left">
  @if (cs.config$ | async; as config) {
    <img class="logo" [src]="config.logoUrl" alt="Company logo" />
  }
</div>

<div class="right">
  <mat-card class="example-card" cdkTrapFocus [cdkTrapFocusAutoCapture]="true">
    <mat-card-content>
      <form [formGroup]="loginFormGroup">
        <mat-form-field>
          <mat-label>Email</mat-label>
          <input id="current-user" cdkFocusInitial type="text" matInput formControlName="email" />
        </mat-form-field>

        <mat-form-field id="password">
          <mat-label>Passwort</mat-label>
          <input
            id="current-password"
            name="current-password"
            #password
            type="password"
            matInput
            autocomplete="off"
            formControlName="password"
          />
        </mat-form-field>
      </form>

      @if (loginErrorCode) {
        <div class="col-warn icon-plus-text">
          <mat-icon>error</mat-icon>
          <span>{{
            loginErrorMessages[loginErrorCode] || loginErrorMessages[loginErrorCodes.LOGIN_FAILED_CODE_0]
          }}</span>
        </div>
      }
    </mat-card-content>
    <mat-card-actions>
      <button mat-stroked-button color="primary" (click)="onSubmit()" [disabled]="loginFormGroup.invalid">
        Anmelden
      </button>
    </mat-card-actions>
    <mat-card-footer>
      <h5>&nbsp;</h5>
    </mat-card-footer>
  </mat-card>
</div>
