/* eslint-disable @angular-eslint/component-selector */
import { HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthRepository } from '../../auth.repository';
import { ConfigService } from '../../config.service';
import { AuthDto } from '../../generated/api/models';
import { ApiService } from '../../generated/api/services';

export enum LoginErrorCodes {
  LOGIN_FAILED_CODE_401,
  LOGIN_FAILED_CODE_500,
  LOGIN_FAILED_CODE_0,
}

@Component({
  selector: 'prisma-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent {
  loginErrorCode?: LoginErrorCodes;

  loginErrorCodes = LoginErrorCodes;

  loginErrorMessages: Record<LoginErrorCodes, string> = {
    [LoginErrorCodes.LOGIN_FAILED_CODE_401]: 'Benutzername oder Password falsch!',
    [LoginErrorCodes.LOGIN_FAILED_CODE_500]: 'Serverfehler!  Bitte versuchen Sie es später',
    [LoginErrorCodes.LOGIN_FAILED_CODE_0]: 'Anmeldung fehlgeschlagen! Bitte versuchen Sie es später.',
  };

  constructor(
    readonly authRepo: AuthRepository,
    readonly api: ApiService,
    readonly router: Router,
    readonly cs: ConfigService,
  ) {}

  loginFormGroup = new FormGroup({
    email: new FormControl<string>('', [Validators.minLength(6), Validators.maxLength(255), Validators.required]),
    password: new FormControl<string>('', [Validators.minLength(6), Validators.maxLength(255), Validators.required]),
  });

  onSubmit() {
    this.api
      .authLogin({
        body: {
          email: this.loginFormGroup.value.email as string,
          password: this.loginFormGroup.value.password as string,
        },
      })
      .subscribe({
        next: (auth: AuthDto) => {
          this.authRepo.updateAuth(auth);
          this.router.navigateByUrl('/d');
        },
        error: (err: HttpErrorResponse) => {
          this.loginErrorCode = `LOGIN_FAILED_CODE_${err.status}` as unknown as LoginErrorCodes;
        },
      });
  }
}
